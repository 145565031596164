export default [
  {
    title: 'Home',
    icon: 'home',
    tagVariant: 'light-warning',
    route: "omc-home",
    action: "read",
    resource: "OMCHomePage"
  },
  {
    title: 'Settlements',
    icon: 'loans',
    tagVariant: 'light-warning',
    route: "omc-settlements",
    action: "read",
    resource: "OMCSettlementsPage"
  },
  {
    title: 'Administrators',
    icon: 'statements',
    tagVariant: 'light-warning',
    route: "omc-administrators",
    action: "read",
    resource: "OMCAdministratorsPage",
    permission_roles: ["administrator"]
  }
]
