<template>
  <li
    v-if="canAccessVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <VerticalNavMenuIcons class="" :icon-type="item.icon" />
      <span class="pl-50 menu-title text-truncate">{{ (item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { get } from 'lodash';
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@core/libs/acl'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import VerticalNavMenuIcons from "@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-items/VerticalNavMenuIcons.vue";

import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    VerticalNavMenuIcons,
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userData() {
      return this.$store.getters[`auth/userData`];
    },
    userRole() {
      const user_type = this.getValueFromSource(this.userData, 'user_type');
      return user_type
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },
  methods: {
    canAccessVerticalNavMenuLink(item) {
      if (this.getValueFromSource(item, 'title') === 'Home') return true
      if (this.isCurrentUserPartnerAdmin || this.isCurrentUserOmcAdmin) {
        const permission_roles = this.getValueFromSource(item, 'permission_roles');
        if (permission_roles && permission_roles.length) {
          return permission_roles.includes(get(this.currentUser, "permission_role"))
        }
      }
      return this.isCurrentUserApproved;
    }
  }
}
</script>
