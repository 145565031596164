export default [
  {
    title: 'Home',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    route: "client-home",
    action: "read",
    resource: "ClientHomePage"
  },
  {
    title: 'Loans',
    icon: 'CreditCardIcon',
    tag: '2',
    tagVariant: 'light-warning',
    route: "client-loans",
    action: "read",
    resource: "ClientLoansPage"
  },
  {
    title: 'Shop',
    icon: 'ShoppingBagIcon',
    tagVariant: 'light-warning',
    route: "client-products",
    action: "read",
    resource: "ClientShopPage"
  },
  {
    title: 'Save',
    icon: 'SaveIcon',
    tagVariant: 'light-warning',
    route: "client-savings",
    action: "read",
    resource: "ClientSavingsPage"
  },
  // {
  //   title: 'History',
  //   icon: 'InfoIcon',
  //   tagVariant: 'light-warning',
  //   route: "client-history",
  //   action: "read",
  //   resource: "ClientHistoryPage"
  // },
]
