export default [
  {
    title: 'Home',
    icon: 'home',
    tagVariant: 'light-warning',
    route: "client-home",
    action: "read",
    resource: "ClientHomePage"
  },
  {
    title: 'Loans',
    icon: 'loans',
    // tag: '2',
    tagVariant: 'light-warning',
    route: "client-loans",
    action: "read",
    resource: "ClientLoansPage"
  },
  // {
  //   title: 'Calculator',
  //   icon: 'calculator',
  //   tagVariant: 'light-warning',
  //   route: "client-loan-calculator",
  //   action: "read",
  //   resource: "ClientShopPage"
  // },
  {
    title: 'Repayments',
    icon: 'repayments',
    // tag: '2',
    tagVariant: 'light-warning',
    route: "client-list-manual-repayments",
    action: "read",
    resource: "ClientListManualRepaymentsPage"
  },
  {
    title: 'Statements',
    icon: 'statements',
    tagVariant: 'light-warning',
    route: "client-statements",
    action: "read",
    resource: "ClientShopPage"
  },
  {
    title: 'Blogs',
    icon: 'blogs',
    tagVariant: 'light-warning',
    route: "client-blogs",
    action: "read",
    resource: "ClientShopPage"
  },
  {
    title: 'Referrals',
    icon: 'referrals',
    children: [
      {
        title: 'Commissions',
        icon: 'referrals',
        tagVariant: 'light-warning',
        route: "referral-commissions",
        action: "read",
        resource: "ClientShopPage"
      },
      {
        title: 'Withdrawals',
        icon: 'withdrawals',
        tagVariant: 'light-warning',
        route: "referral-commissions-withdrawals",
        action: "read",
        resource: "ClientShopPage"
      }
    ]
  },
  // {
  //   title: 'History',
  //   icon: 'InfoIcon',
  //   tagVariant: 'light-warning',
  //   route: "client-history",
  //   action: "read",
  //   resource: "ClientHistoryPage"
  // },
]
