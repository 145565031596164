export default [
  {
    title: 'Home',
    icon: 'home',
    tagVariant: 'light-warning',
    route: "partner-home",
    action: "read",
    resource: "PartnerHomePage"
  },
  {
    title: 'Loans',
    icon: 'loans',
    tagVariant: 'light-warning',
    route: "partner-all-loans",
    action: "read",
    resource: "PartnerLoansPage"
  },
  {
    title: 'Repayments',
    icon: 'repayments',
    tagVariant: 'light-warning',
    route: "partner-repayments",
    action: "read",
    resource: "PartnerRepaymentsPage"
  },
  {
    title: 'Manage Users',
    icon: 'statements',
    tagVariant: 'light-warning',
    route: "partner-manage-users",
    action: "read",
    resource: "PartnerUsersPage",
    permission_roles: ["administrator"]
  }
]
