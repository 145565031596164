<template>
  <div
      class="main-menu menu-fixed menu-accordion menu-shadow"
      :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'dark' ? 'menu-dark' : 'menu-light'
    ]"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
          name="header"
          :toggleVerticalMenuActive="toggleVerticalMenuActive"
          :toggleCollapsed="toggleCollapsed"
          :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item" :class="!isVerticalMenuCollapsed ? 'nav-logo-full' : 'nav-logo-small'">
            <b-link
                class="navbar-brand"
                to="/"
            >
              <span class="brand-logo">
                <b-img
                    :class="!isVerticalMenuCollapsed ? 'logo-full' : 'logo-small'"
                    :src="!isVerticalMenuCollapsed ? appLogoImage : appMobileModeLogoImage"
                    alt="logo"
                />
              </span>
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <!-- <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    /> -->

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >

      <!-- Navigation items-->
      <vertical-nav-menu-items
        :items="navigationItems"
        class="navigation navigation-main"
      />

      <div v-if="isApproved && isCurrentUserClient">
        <div class="card-body d-md-none">
          <div class="border-primary rounded-lg">
            <div class="card-body">
              <user-statistics />
            </div>
          </div>
        </div>
      </div>

    </vue-perfect-scrollbar>
    <!-- /main menu content-->


  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical';
import UserStatistics from "@/pages/client/home/UserStatistics.vue";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { BLink, BImg } from 'bootstrap-vue';
import { provide, computed, ref } from '@vue/composition-api';
import { $themeConfig } from '@themeConfig';

import useAppConfig from '@core/app-config/useAppConfig';
import ClientMenuItems from "@/navigation/client";
import PartnerAdminMenuItems from "@/navigation/partner_admin";
import OmcAdminMenuItems from "@/navigation/omc_admin";

import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue';
import useVerticalNavMenu from './useVerticalNavMenu';

export default {
  components: {
    UserStatistics,
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    settings() {
      return this.$store.getters[`auth/settings`];
    },
    userData() {
      return this.$store.getters[`auth/userData`];
    },
    isApproved() {
      const version = this.getValueFromSource(this.userData, "version", 2)
      if (version === 1) return true
      return this.getValueFromSource(this.userData, 'identity_verification.status', 'pending') === 'approved';
    },
    navigationItems() {
      const user_type = this.getValueFromSource(this.userData, 'user_type');
      if (user_type === "partner_admin"){
        return this.PartnerAdminMenuItems
      }
      if (user_type === "omc_staff_admin") {
        return this.OmcAdminMenuItems
      }
      return this.ClientMenuItems
    }
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()
    console.log("skin", skin.value)

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage, appMobileModeLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
      ClientMenuItems,
      PartnerAdminMenuItems,
      OmcAdminMenuItems,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appMobileModeLogoImage
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
