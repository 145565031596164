<template>
  <div v-if="getValueFromSource(dashboardData, 'total_loan_owed', 0) > 0">
    <div class="">
      <div class="d-md-flex align-items-center">

        <div class="mr-2">
          <div>
            <p class="m-0 p-0">
              Loan Owed
            </p>
            <h1> Gh¢{{ formatMoney(getValueFromSource(dashboardData, 'total_loan_owed', 0)) }} </h1>
          </div>
        </div>
        <div class="mr-2">
          <div>
            <p class="m-0 p-0">
              Loan Paid
            </p>
            <h1> Gh¢{{ formatMoney(getValueFromSource(dashboardData, 'total_paid', 0)) }} </h1>
          </div>
        </div>

        <div class="mr-1">
          <div class="" style="min-width: 200px">
            <p class="m-0 p-0">
              Repayment Progress
            </p>

            <div class="mt-50">
              <b-progress :value="getValueFromSource(dashboardData, 'total_paid', 0)" :max="getValueFromSource(dashboardData, 'total_loan_owed', 100)" height="7px" />
            </div>
            <div v-if="getValueFromSource(dashboardData, 'total_penal_charge', 0) !== 0" class="mt-50">
              <small class="text-danger">Gh¢
                {{ formatMoney(getValueFromSource(dashboardData, 'total_penal_charge', 0)) }}</small>
            </div>
          </div>
        </div>
        <div>
          <h1 class="m-0 p-0">
            GH¢ {{ formatMoney(getValueFromSource(dashboardData, 'remaining_balance', 0)) }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BProgress,
  BProgressBar
} from 'bootstrap-vue'
import {
  get
} from "lodash";

export default {
  components: {
    BProgress,
    BProgressBar
  },
  data() {
    return {
      loading: false,
      dashboardData: {
        total_penal_charge: 0,
        total_paid: 0,
        remaining_balance: 0,
      }
    }
  },
  async created() {
    await this.fetchDashboardData();
  },
  methods: {
    async fetchDashboardData() {
      try {
        this.loading = true;

        const request = await this.useJwt().clientService.fetchDashboardData();
        const {
          data
        } = request.data;

        this.dashboardData = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }

  }
}
</script>
