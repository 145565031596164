<template>
    <component :is="getIcon" :key="iconType" />
</template>

<script>
import NavHome from '@/assets/svg/NavHome.svg';
import NavLoan from '@/assets/svg/NavLoan.svg';
import NavBlog from '@/assets/svg/NavBlog.svg';
import NavCalculator from '@/assets/svg/NavCalculator.svg';
import NavRepayments from '@/assets/svg/NavRepayments.svg';
import NavStatements from '@/assets/svg/NavStatements.svg'
import NavTarget from '@/assets/svg/NavTarget.svg'
import NavCreditCard from '@/assets/svg/NavCreditCard.svg'

export default {
  name: "VerticalNavMenuIcons",
  components: {
    NavHome,
    NavLoan,
    NavCalculator,
    NavRepayments,
    NavStatements,
    NavTarget,
    NavCreditCard
  },
  props: {
    iconType: {
      type: String,
      required: true,
      default: ""
    }
  },
  computed: {
    getIcon() {
      const iconTypes = {
        home: NavHome,
        loans: NavLoan,
        calculator: NavCalculator,
        repayments: NavRepayments,
        statements: NavStatements,
        blogs: NavBlog,
        referrals: NavTarget,
        withdrawals: NavCreditCard
      }
      return iconTypes[this.iconType];
    }
  }
}
</script>

<style scoped>

</style>
